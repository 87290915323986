<template>
  <div class="contact-form">
    <div class="actual-form">
    <md-field>
      <label>Name</label>
      <md-input class="md-accent" type="name" v-model="name" required></md-input>
    </md-field>

    <md-field>
    <label>Email</label>
      <md-input class="md-accent" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"  type="email" v-model="email" required></md-input>
    </md-field>

    <md-field>
      <label>Subject</label>
      <md-input class="md-accent" v-model="subject" required></md-input>
      <span class="md-error">There is an error</span>
    </md-field>

    <md-field class="md-accent" >
      <label>Message</label>
      <md-textarea class="md-accent" v-model="message" required></md-textarea>
      <span class="md-error">There is an error</span>
    </md-field>
  </div>
  </div>
  
</template>

<script>

export default {
    name: 'ContactPage',
    data: () => ({
    menuVisible: false,
    name: null,
    email: null,
    subject: null,
    message: null
  })
}
</script>

<style lang="scss" scoped>
.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actual-form {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 500px;
}

</style>