<template>
  <div>
    <div>
      <span class="md-display-2">SpectraFiX</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>Speclarity's SpectraFiX Spectral Immunity platform draws upon the vast experience gained over the years in active mitigation of a large portfolio of uplink interference scenarios.
             The mitigation resulting in dramatic improvement of KPIs, such as uplink and downlink coverage, throughput, retainability and accessibility.
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>The solution provides spectral immunity to 4G/LTE and 5G/NR deployments and support protection of 4 simultaneous radios / carriers. 
            It provides a complete suite of tools for the automation of RAN performance optimization processes and create a secure, reliable, and resilient network under the most challenging interference and jamming scenarios.
          </span>
        </div>
        <br>        
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/spectrafix.png" alt="spectrafix">
      </div>      
    </div>


    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>SpectraFiX is designed to achieve simple, rapid and dense deployment.
             The compact 2RU product is seamlessly installed between the RRUs and BBUs of up to 4 radios. 
             No base-station configuration is required. Built-in Optical Line Protection (OLP) ensures continuous operation under any failure including unit loss of power.
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>SpectraVue™, a cloud-based platform, is used for management of a scalable SpectraFiX deployment. 
            SpectraVue also enables remote view of spectral data and extracted signals recorded by the SpectraFiX units.
          </span>
        </div>
      </div>        
    </div>
    <br>
    <br>
    <md-table>
      <md-table-row>
        <md-table-head >CPRI Rate</md-table-head>
        <md-table-head>3, 5, 7 and 10</md-table-head>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Channel Configuration</md-table-cell>
        <md-table-cell>5/10/15/20 MHz LTE Channels. Up to 4 Channels per Sector</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Processing Bandwidth Capacity</md-table-cell>
        <md-table-cell>160MHz</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Antenna Configurations (Per Sector)</md-table-cell>
        <md-table-cell>2T/2R and 4T/4R</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Gain</md-table-cell>
        <md-table-cell>0 dB</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Interference Cancellation</md-table-cell>
        <md-table-cell>Up to 40 dB or Interference SNR</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Total Delay</md-table-cell>
        <md-table-cell> &lt;24 &#181;sec </md-table-cell>        
      </md-table-row>
      <br>
      <md-table-row>
        <md-table-head >OLP (Optical Line Protection)</md-table-head>
        <md-table-head></md-table-head>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Optical Bypass Mode Loss</md-table-cell>
        <md-table-cell>3 dB typical</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell >Switching time</md-table-cell>
        <md-table-cell> &lt;10 ms </md-table-cell>        
      </md-table-row>
      <br>
      <md-table-row>
        <md-table-head >Power</md-table-head>
        <md-table-head></md-table-head>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Input power supply voltage</md-table-cell>
        <md-table-cell>DC: -48 V / Max. 5 A (36 to 60VDC)</md-table-cell>        
      </md-table-row>
      
      <md-table-row>
        <md-table-cell></md-table-cell>
        <md-table-cell>AC: 90 to 264 VAC / Typ 3A @ 115 VAC, Typ 1.5A @ 230 VAC</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Power Consumption</md-table-cell>
        <md-table-cell>idle state &lt; 150W, Max &lt; 250W</md-table-cell>        
      </md-table-row>
      <br>
      <md-table-row>
        <md-table-head >Mechanical Specifications</md-table-head>
        <md-table-head></md-table-head>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Dimensions L x W x H mm(in)</md-table-cell>
        <md-table-cell>350mm x 430mm x 88mm (14" x 17" x 3.5")</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Weight</md-table-cell>
        <md-table-cell>&lt;10Kg (22lb)</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Temperature Range</md-table-cell>
        <md-table-cell>0 to +50&#176;</md-table-cell>        
      </md-table-row>

      <md-table-row>
        <md-table-cell>Ingress Protection</md-table-cell>
        <md-table-cell>IP20, indoor unit</md-table-cell>        
      </md-table-row>

    </md-table>
  </div>
</template>

<script>

export default {
    name: 'TechnologyPage',
    data: () => ({
    menuVisible: false
  })
}
</script>

<style lang="scss" scoped>

.info-img {
  background-image: url('../assets/bts.jpg');
  width: 350px;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //background-size: contain;
  //background-repeat: no-repeat;
  transition: transform 0.3s;
  margin: 10px;
}

.info-img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.set-space {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

</style>