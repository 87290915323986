<template>  
  <div>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">    
    <div class="md-layout md-gutter md-alignment-center-space-around">
      <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div>
          <span class="md-display-2">ENSURING SPECTRUM REMAINS CLEAR</span>
        </div> 
        <br>
        <div>
          <md-list>            
            <md-list-item>              
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">ENABLES COVERAGE & CAPACITY</span>
            </md-list-item>
            <md-list-item>
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">PROTECTS THROUGHPUT & SNR</span>
            </md-list-item>
            <md-list-item>
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">STRENGTHENS RETAINABILITY</span>
            </md-list-item>
            <md-list-item>
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">REDUCES CALL DROP RATE</span>
            </md-list-item>
            <md-list-item>
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">MAINTAINS ACCESSIBILITY</span>
            </md-list-item>
            <md-list-item>
              <md-icon>double_arrow</md-icon>
              <span class="md-list-item-text md-headline">PREVENTS PACKET LOSS</span> 
            </md-list-item>
          </md-list>                      
        </div>
        <br><br>
        <div>
          <div>
            <span class="md-headline">Protection for your Cellular Network</span>            
          </div>
          <br>
          <div>
            <span class="md-subheading">
              As cellular traffic grows, more bands, more antennas and more power is added at cell sites in your network and in neighbour networks. 
              With this, the risks of experiencing RF interference increase, whether self interference or interference from other networks.  
              Interference into one or more Uplink bands can be experienced from various mechanisms. These include for example:
            </span>            
          </div>
          <md-list>            
            <md-list-item>
              <md-icon>label_important</md-icon>              
              <span class="md-list-item-text set-space">Cross-border Non-Harmomised spectrum band plans especially between Band 7 and Band 41 networks</span>
            </md-list-item>
            <md-list-item>
              <md-icon>label_important</md-icon>
              <span class="md-list-item-text set-space">UHF High-Power Terrestrial TV transmissions from neighbouring countries</span>
            </md-list-item>
            <md-list-item>
              <md-icon>label_important</md-icon>
              <span class="md-list-item-text set-space">Exaggerated effects of the above in high Troposcatter environments carrying transmissions over 100's kms</span>
            </md-list-item>
            <md-list-item>
              <md-icon>label_important</md-icon>
              <span class="md-list-item-text set-space">TDD timing mis-alignment from distant sites and from distant networks under Troposcatter conditions</span>
            </md-list-item>
            <md-list-item>
              <md-icon>label_important</md-icon>
              <span class="md-list-item-text set-space">Passive Intermodulation (PIM) due to the RF coupling of multiple spectrum bands at sites</span>
            </md-list-item>
            <md-list-item>
              <md-icon>label_important</md-icon>
              <span class="md-list-item-text set-space">Intentional jamming, unregulated use of broadband repeaters, and other RF noise sources</span>
            </md-list-item>
          </md-list>
        </div>
      </div>
      <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/bts.jpg" alt="bts">
      </div>
    </div>
    
    <div class="md-layout md-gutter md-alignment-top-center">
      <!-- ------------- PIM FIX --------------- -->
      <md-card class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-elevation-10 home-card">
        <md-card-media>
          <img class="card-img" src="../assets/pimfix.gif" alt="pimfix">
        </md-card-media>

        <md-card-header>
          <div class="md-title">SpectraFIX™</div>
          <div class="md-subhead">Fixing passive intermodulation</div>
        </md-card-header>

        <md-card-expand>
          <md-card-actions md-alignment="space-between">
            <div>
              <md-button v-on:click="moreData()">More data</md-button>              
            </div>

            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
              </md-button>
            </md-card-expand-trigger>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content>
              The world's most powerful PIM cancellation technology removing up to 25dB of unwanted passive intermods and restoring noise floor by utilizing innovative centralized architecture, extensive processing power and patented efficient and powerful algorithms.
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>      
      <!-- ------------- View --------------- -->
      <md-card class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-elevation-10 home-card">
        <md-card-media>
          <img class="card-img" src="../assets/view.jpg" alt="View">
        </md-card-media>

        <md-card-header>
          <div class="md-title">SpectraVue™</div>
          <div class="md-subhead">Fixing passive intermodulation</div>
        </md-card-header>

        <md-card-expand>
          <md-card-actions md-alignment="space-between">
            <!--<div>
              <md-button>More data</md-button>              
            </div>-->

            <md-card-expand-trigger>
              <md-button class="md-icon-button">
                <md-icon>keyboard_arrow_down</md-icon>
              </md-button>
            </md-card-expand-trigger>
          </md-card-actions>

          <md-card-expand-content>
            <md-card-content>
              SpectraVue™, a cloud-based platform, is used for management of a scalable SpectraFiX deployment. SpectraVue also enables remote view of spectral data and extracted signals recorded by the SpectraFiX units.
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>
    </div>
  </div>
</template>

<script>

export default{
  name: 'HomePage',    
  data: () => ({
  menuVisible: false
  }),
  methods: {
    moreData() {      
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // For smooth scrolling (optional)
      });
      history.pushState({ page: "home" }, "home", "/");
      this.$emit('spectra-page', true);
    }
  }
}
</script>

<style lang="scss" scoped>

.cards-holder {
  display: flex;
  flex-direction: row;  
}

.card-img {
  height: 200px;
  width: auto;
}
.home-card {
  margin: 5px;
  transition: transform 0.3s;
}

.home-card:hover {
  transform: scale(1.02);
}

.set-space {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

.info-img {
  background-image: url('../assets/bts.jpg');
  width: auto;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //background-size: contain;
  //background-repeat: no-repeat;
  transition: transform 0.3s;
  margin: 10px;
}

.info-img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

</style>