<template>
  <div>
    <div>
      <span class="md-display-2">SpectraFiX - Technology</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>SpectraFiX technology is designed to clean the incoming signals received by the receiver antennas by removing undesired interference and jamming signals while keeping the desired communication signals for the receiver processing. 
            In order to do so the technology is composed of three major signal processing layers:
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span><b>Signal separation</b> - this layer is identifying and distinguishing between desired signals and interfering signals. 
            The separation is based on time, frequency, and signature (repeated patterns) of the desired and interfering signals. 
            This layer enables the autonomous operation of the SpectraFiX technology without complex integration with the protected receiver.
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span><b>Interference channel tracking </b> - this layer is acquiring and tracking in real time changes in the channel between the interference sources and the receiver. 
            This layer enables fast acquisition and tracking at the order of microseconds required for the mitigation of rapidly changing interferers.
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span><b>Interference cancellation</b> - finally this layer is removing the interference using smart antenna DSP technology that manipulate the antenna array radiation pattern to steer a null (or multiple nulls) towards the interferer(s). 
            The null can be created in multiple domains based on the receiver antenna array including polarisation, azimuth, and elevation of the interfering source. 
            This layer enables removal of the interfering signal by up to 40dB and remove multiple interfering sources simultaneously.
          </span>
        </div>
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/signal.png" alt="signal">
      </div>      
    </div>
    <br>
    <div>
      <span class="md-display-2">SpectraFiX - Effect on KPI's</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>Interference mitigation and SNR improvement translate to dramatic improvement of the network and site performance. This includes:
          </span>
        </div>
        <br>
        <md-list>            
          <md-list-item>
            <md-icon>label_important</md-icon>              
            <span class="md-list-item-text set-space">Increase in coverage</span>
          </md-list-item>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">Increase in throughput and capacity</span>
          </md-list-item>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">Reduction in packet loss</span>
          </md-list-item>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">Reduction in call drops</span>
          </md-list-item>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">Reduction in service denial</span>
          </md-list-item>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">Reduction in terminal transmit power & improved battery life</span>
          </md-list-item>
        </md-list>
        <br>
        <div class="md-subheading">
          <span>While SpectraFiX, being a receiver technology, mitigates interference and improves the SNR on the uplink path, 
            the improvement in KPI's is achieved on both Uplink and Downlink communication due to several reasons:
          </span>
        </div>
        <md-list>            
          <br>
          <md-list-item>
            <md-icon>label_important</md-icon>              
            <span class="md-list-item-text set-space">Uplink is typically the limiting link due to the limitation in UE transmit power and the need for very high sensitivity in the uplink receiver. 
              This sensitivity is supported by SpectraFiX SNR enhancement and as a result improves coverage for both uplink and downlink communication.</span>
          </md-list-item>
          <br>
          <md-list-item>
            <md-icon>label_important</md-icon>
            <span class="md-list-item-text set-space">LTE and 5G/NR use acknowledgement feedback protocol - hence improving the uplink communication improves the efficiency of the downlink communication as well.</span>
          </md-list-item>          
          <br>
        </md-list>        
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/improve.png" alt="improve">
      </div>      
    </div>
    


  </div>
</template>

<script>

export default {
    name: 'TechnologyPage',
    data: () => ({
    menuVisible: false
  })
}
</script>

<style lang="scss" scoped>

.info-img {
  background-image: url('../assets/bts.jpg');
  width: 350px;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //background-size: contain;
  //background-repeat: no-repeat;
  transition: transform 0.3s;
  margin: 10px;
}

.info-img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.set-space {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

</style>