<template>
  <div class="page-container">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <md-app md-waterfall md-mode="fixed-last">
      <md-app-toolbar class="md-large md-dense md-primary">
        <div class="md-toolbar-row">
          <img src="./assets/SpeclarityLogo.png" class="main-logo"/>
          <md-tabs class="md-primary">
            <md-tab id="tab-home" md-label="Home" @click="homeClick"></md-tab>
            <md-tab id="tab-technology" md-label="Technology" @click="technologyClick"></md-tab>
            <md-tab id="tab-cases" md-label="Case studies" @click="casesClick"></md-tab>
            <md-tab id="tab-about" md-label="About us" @click="aboutClick"></md-tab>
            <md-tab id="tab-contact" md-label="Contact us" @click="contactClick"></md-tab>
          </md-tabs>
        </div>
      </md-app-toolbar>


      <md-app-content>
        <home-page             
          v-on:spectra-page="spectraPageClick"
          v-if="pages.home"/>
        <technology-page v-if="pages.technology"/>
        <cases-page v-if="pages.cases"/>
        <about-page v-if="pages.about"/>
        <contact-page v-if="pages.contact"/>
        <spectra-page v-if="pages.spectra"/>
      </md-app-content>      
    </md-app>
    <md-content class="md-accent app-footer">
      <div class="footer-item">
        <md-icon>home</md-icon>
        <span> Speclarity Ltd. Dimostheni Severi 25-27, Metropolis Tower, Building B, 2nd floor, 1080, Nicosia, Cyprus</span> 
      </div>
      <div class="footer-item">
        <md-icon>call</md-icon>
        <span> +357 22 509 850 </span>
        <md-icon>email</md-icon>
        <span><a href="mailto:sales@speclarity.com" class="email-link">sales@speclarity.com</a></span>
      </div>
      <div class="footer-item">
        <span>©2023 Speclarity Limited. All rights reserved. The information in this website is proprietary to Speclarity. It may not be used, reproduced, disclosed, or exported without the written approval of Speclarity.</span>
      </div>
      
    </md-content>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
import TechnologyPage from './components/TechnologyPage.vue'
import CasesPage from './components/CasesPage.vue'
import AboutPage from './components/AboutPage.vue'
import ContactPage from './components/ContactPage.vue'
import SpectraPage from './components/SpectraPage.vue'
export default {
  name: 'App',
  components: {
    HomePage,
    TechnologyPage,
    CasesPage,
    AboutPage,
    ContactPage,
    SpectraPage
  },
  data: () => ({
    menuVisible: false,
    pages: {
      home: true,
      technology: false,
      cases: false,
      about: false,
      contact: false,
      spectra: false
    },  
    
  }),
  methods: {
    homeClick() {      
      this.pages.home = true;
      this.pages.technology = false;
      this.pages.cases = false;
      this.pages.about = false;
      this.pages.contact = false;
      this.pages.spectra = false;      
    },
    technologyClick() {
      this.pages.home = false;
      this.pages.technology = true;
      this.pages.cases = false;
      this.pages.about = false;
      this.pages.contact = false;
      this.pages.spectra = false;
    },
    casesClick() {
      this.pages.home = false;
      this.pages.technology = false;
      this.pages.cases = true;
      this.pages.about = false;
      this.pages.contact = false;
      this.pages.spectra = false;
    },
    aboutClick() {
      this.pages.home = false;
      this.pages.technology = false;
      this.pages.cases = false;
      this.pages.about = true;
      this.pages.contact = false;
      this.pages.spectra = false;
    },
    contactClick() {
      this.pages.home = false;
      this.pages.technology = false;
      this.pages.cases = false;
      this.pages.about = false;
      this.pages.contact = true;
      this.pages.spectra = false;
    },
    spectraPageClick() {
      this.pages.home = false;
      this.pages.technology = false;
      this.pages.cases = false;
      this.pages.about = false;
      this.pages.contact = false;
      this.pages.spectra = true;
    },
    handleBackButton() {
      // Your method logic when the back button is clicked
      // Scroll to the top of the page      
      this.homeClick();
      console.log('Back button clicked');
    }
  },
  mounted() {
    // Add an event listener to the onpopstate event
    window.onpopstate = () => {
      this.handleBackButton();
    };    
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.onpopstate = null;
  }
}
</script>

<style lang="scss" scoped>
  .page-container {
    font-family: 'Work Sans', sans-serif;
  }

  .md-app {
    
    //max-height: 400px;
    border: 1px solid rgba(#000, .12);
  }

   // Demo purposes only
  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }
  .main-logo {
    height: auto;
    width: 250px;
    margin-top: 10px;
  }
  .app-footer {
    height: 150px;
    padding: 20px 0;
  }

  .footer-item {
    margin-left: 20px;
    margin-right: 20px;
  }

  .email-link:link {
    color: white;
  }

  .email-link:visited {
    color: white;
  }

  .email-link:hover {
    color: #d12639;
  }
</style>

