<template>
  <div>
    <div>
      <span class="md-display-2">Cross-Border B7/41 Interference (Canada/US)</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>This case study presents a cross-border interference scenario causing severe interference that degraded dramatically the operation of 4G/5G networks deployed near the US–Canada border. The interference is created due to the different regulatory spectrum assignments in the two countries in the 2600MHz range. 
            In the US, the 2600MHz spectrum usage is defined and used as TDD (Band 41) while in Canada this spectrum is defined and used as FDD (Band 7).
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>The difference in Uplink/Downlink duplexing schemes between the neighboring countries results in significant interference that can degrade network performance on both sides of the border.
             Deploying SpectraFiX interference mitigation solution on the Canadian cellular network resulted in cancellation of the interference and restoration of the 4G cellular network performance.
          </span>
        </div>
        <br>        
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/canada.png" alt="signal">
        <img class="info-img" src="../assets/canada_before_after.png" alt="signal">
      </div>      
    </div>
    <br>
    
    <div>
      <span class="md-display-2">Tropospheric ducting of TV Signals (Florida, US)</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>Tropospheric ducting causes very strong propagation of RF signals over long distances often 100's of kilometers, resulting in interference from far away transmitting sources (interferers) and receiving sources (victims). 
            Ducting intensity varies in time and location and is typically (though not always) stronger above warm water. Some geographical areas are known for having significant ducting phenomenon. 
            These include the Mediterranean coast, the Caribbean, Gulf of Mexico and Florida, The Arabian Gulf and India. 
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>The case presented shows how ducting between Cuban TV transmitters in 700MHz and a cellular network in Florida results in significant interferences.
              Deploying SpectraFiX interference mitigation solution in the Florida Keys resulted in cancellation of the interference and restoration of network performance. 
              Although not shown, Cuba adopts Band 3 FDD for its cellular networks which causes interference to US Band 2 FDD Uplink during ducting events. 
            SpectraFiX was also deployed at several Sites using Band 2.

          </span>
        </div>
        <br>        
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/cuba.png" alt="signal">
        <img class="info-img" src="../assets/cuba_before_after.png" alt="signal">
      </div>      
    </div>
    <br>
    
    <div>
      <span class="md-display-2">Mexican Prison Jammer (El Paso, Texas)</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>In this case SpectraFiX mitigated a prison jammer causing severe interference disrupting the cellular network tens of kilometers away from the prison facility.
             The jamming system installed at a federal prison in Ciudad Juarez in Mexico created a disrupting interference at 700MHz to cellular networks across the border in El Paso, Texas.
             The interference was so strong increasing noise floor by 15~25dB at almost 100 cellular sites at distances of 10~20km away from the jamming source. 

          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>Deploying SpectraFiX interference mitigation solution at dozens of cell sites in the city of El Paso resulted in cancellation of the interference and restoration of network performance.</span>
        </div>
        <br>        
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
        <img class="info-img" src="../assets/mexico.png" alt="signal">
        <img class="info-img" src="../assets/mexico_before_after.png" alt="signal">
      </div>      
    </div>
    <br>

  </div>
</template>

<script>

export default {
    name: 'TechnologyPage',
    data: () => ({
    menuVisible: false
  })
}
</script>

<style lang="scss" scoped>

.info-img {
  background-image: url('../assets/bts.jpg');
  width: 350px;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //background-size: contain;
  //background-repeat: no-repeat;
  transition: transform 0.3s;
  margin: 10px;
}

.info-img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.set-space {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

</style>