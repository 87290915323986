<template>
  <div>
    <div>
      <span class="md-display-2">About Speclarity</span>
    </div>
    <br>
    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <div class="md-subheading">
          <span>Speclarity focuses on innovation in smart antenna and interference cancellation technologies enabling wireless service providers to extract the most out-of their spectrum and upgrade their mobile broadband network to unprecedented capacity and spectral efficiency. 
            Speclarity's SpectraFiX™ products enable cellular base-stations to maintain their high quality operation and capacity in the presence of strong foreign interference generated by narrow and wide-band signals while performing acquisition and tracking automatically. 
            The company's solution has been successfully deployed by leading global cellular operators, protecting 3G and 4G/LTE sites from a broad range of interference scenarios.
          </span>
        </div>
        <br>
        <div class="md-subheading">
          <span>Speclarity was founded in 2023, utilizing technology that was developed for over a decade and was widely deployed in public and private networks around the world. Speclarity’s technology and products protects and provide resiliency to public and critical infrastructure networks by providing spectral immunity against self, external, cross-border and intentional (jamming) threats.

          </span>
        </div>
        <br>        
      </div>            
    </div>
    <br>
  </div>
</template>

<script>

export default {
    name: 'AboutPage',
    data: () => ({
    menuVisible: false
  })
}
</script>

<style lang="scss" scoped>

</style>